import { Router } from 'next/router';
import React, { useEffect } from 'react';

// import { removeInvendrDom } from '../utility';

declare global {
  interface Window {
    get_invendr_data: (data: any) => void;
    invendrData: {
      term: any;
      content: any;
      medium: any;
      campaign: any;
      source: any;
      mediaNumber: any;
      active_rule: any;
    };
    phone: string;
    email: string;
  }
}
export const loadInvender = (invendrKey: any, pageKey: string) => {
  const script = document?.createElement('script');
  if (script && invendrKey && invendrKey !== window.location.pathname) {
    script.src = `https://dev-invendr-dni.devv.ca/api/script/${invendrKey}.js?callback=get_invendr_data`;
    script.id = pageKey;
    script.async = true;
    if (
      typeof pageKey === 'string' &&
      document.querySelectorAll(`#${pageKey}`).length < 1
    ) {
      document.body.appendChild(script);
    }
    // on refresh update invendr
    // removeInvendrDom();
  }
};
const InvendrScript = (props: any) => {
  const { invendrKey, pageKey } = props;
  if (typeof window !== 'undefined') {
    window.get_invendr_data = (data: any) => {
      window.invendrData = data;
    };

    loadInvender(invendrKey, pageKey);
  }
  useEffect(() => {
    Router.events.on('routeChangeComplete', loadInvender);
    return () => {
      Router.events.off('routeChangeComplete', loadInvender);
      // removeInvendrDom();
    };
  }, []);
  return <></>;
};
export default InvendrScript;
